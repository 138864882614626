import './bootstrap';
import './custom-jquery';


function setNavbarHeight() {
    // Get the DOM Node if you pass in a string
    let menuBtn = document.getElementById('navigation');
    if(menuBtn) {
        // debugger
        let styles = window.getComputedStyle(menuBtn),
            margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']),
            result = Math.ceil(menuBtn.offsetHeight + margin),
            body = document.querySelector('body');
        
            body.style.setProperty('--nav-height', result + "px");
    }
    setFooterHeight()
}

function setFooterHeight() {
    // Get the DOM Node if you pass in a string
    let menuBtn = document.getElementById('footer');
    if(menuBtn) {
        // debugger
        let styles = window.getComputedStyle(menuBtn),
            margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']),
            result = Math.ceil(menuBtn.offsetHeight + margin + 1),
            body = document.querySelector('body');
        
            body.style.setProperty('--footer-height', result + "px");
    }
}

//DEV HELPERS
function debounce(func){
    var timer;
    return function(event){
        if(timer) clearTimeout(timer);
        timer = setTimeout(func,100,event);
    };
}

function setAdvatages(){
    // get all the elements in the list and videos
    let options = document.querySelectorAll(".message > li");
    let contents = document.querySelectorAll(".content-background-container > .content-container > .content > .content-list > li");
    
    function listenToOptions() {
        let oldIndex = Array.prototype.indexOf.call(options, document.querySelector(".message > li.active"));
        $(contents[oldIndex]).find('iframe').each(function(){
            this.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
        });
        removeActiveClasses(options);
        // add active class to clicked element
        this.classList.add('active');
        
        // obtain index
        let activeIndex = Array.prototype.indexOf.call(options, this);
        $(contents[activeIndex]).find('iframe').each(function(){
            this.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
        });
        
        // show same video section as index clicked
        removeActiveClasses(contents);
        contents[activeIndex].classList.add('active')


    }
        
    // add event listener on click to elements
    options.forEach(option => {
        option.addEventListener('click', listenToOptions)        
    })
        
}

function setLocations(){
    // get all the elements in the list and videos
    let options = document.querySelectorAll(".locations > .locations-container > .responsive > .selection-list > .selection-list-item");
    let contents = document.querySelectorAll(".locations > .locations-container > .responsive > .selection-list-data > .selection-list-data-item");

    function listenToOptions() {
        removeActiveClasses(options);
        // add active class to clicked element
        this.classList.add('active');
        
        // obtain index
        let activeIndex = Array.prototype.indexOf.call(options, this);
        
        // show same video section as index clicked
        removeActiveClasses(contents);
        contents[activeIndex].classList.add('active')
    }
    
    
    // add event listener on click to elements
    options.forEach(option => {
        option.addEventListener('click', listenToOptions)        
    })
    
}

function removeActiveClasses(nodeArray) {
    nodeArray.forEach(
        option => {
            option.classList.remove("active");
        }
    )
}


const myModal = new bootstrap.Modal('#contact_modal', {
    keyboard: false
  })
  
//RUNTIME
window.onload = function() {
    setNavbarHeight();
    setAdvatages();
    setLocations();
    // OpenMenu();
    // scrollProducts();

    //EVENT LISTENERS
    // openCurtain();

    window.addEventListener("resize",debounce(setNavbarHeight));
}