$(document).ready(function() {
    setOwlCarousel();
    setDropdownMenus();
    addRequiredFieldIndicators();
});


function setOwlCarousel() {
    //landing page owl carousels
    let $owlBlog = $( '.owl-carousel.blog' );
    let $owlCerts = $( '.owl-carousel.certifications' );
    let $owlProducts = $( '.owl-carousel.products' );


    $owlBlog.owlCarousel({
        loop:true,
        items:4,
        margin:25,
        autoplay:true,
        dots: true,
        autoplayTimeout:8000,
        autoplayHoverPause:true,
        responsive : {
            0 : {
                items:1,
                
            },
            620 : {
                items:2,
                
            },
            780 : {
                items:3,
                
            },
            1200 : {
                items:4,
                
            }
        }
    });
    
    $owlCerts.owlCarousel({
        loop:true,
        items:4,
        margin:25,
        autoplay:true,
        autoplayTimeout:8000,
        autoplayHoverPause:true,
        responsive : {
            0 : {
                items:1,
                
            },
            480 : {
                items:3,
                
            },
            1200 : {
                items:4,
                
            }
        }
    });

    $owlProducts.owlCarousel({
        loop:true,
        items:3,
        margin:25,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        responsive : {
            0 : {
                items:1,
                dots:true,
                
            },
            920 : {
                items:2,
                dots:false,
                
            },
            1200 : {
                items:3,
                dots:false,

            }
        }
    });
}

function setDropdownMenus(){
    // PREVENT INSIDE CLICK DROPDOWN 
    $('.dropdown-menu').on("click.bs.dropdown", function (e) {
        e.stopPropagation();
        // e.preventDefault();
    });
}

function addRequiredFieldIndicators() {
    let forms = $("form");
    forms.find(':input').each(function() {
        if (!$(this).prop('required')) {
            // console.log("not required");
        } else {
            // console.log("required");
            let prevLabel = $(this).parent().find('label');
            prevLabel.append(' *')
        }
    })
}

